<template>
<div>
  <v-stepper v-model="e1">

    <v-col cols="12" sm="5" class="mb-n9">
      <v-toolbar-title
        class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
      >
        <v-icon class="mr-2 mt-n1" color="error"
          >mdi-shopping</v-icon
        >Evaluation
      </v-toolbar-title>
      <p class="grey--text ml-8 mt-n2">Tender Evaluation Page</p>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-col>

    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" color="primary">
        Received Bids
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2" color="primary">
        Technical Evaluation
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3" color="primary">
        Financial Evaluation
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4" color="primary">
        Awarding
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-progress-linear
      color="primary lighten-3"
      buffer-value="0"
      :value="this.progress_value"
      stream
      class="mt-"
    ></v-progress-linear>

    <v-stepper-items>
      <v-form ref="form" v-model="valid">
        <v-stepper-content step="1">
          <v-data-table
            :headers="headers_applicants"
            :items="applicants"
            sort-by="name"
            class="elevation-0 mb-2"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  class="error--text text--darken-1 justify-center"
                >
                  <v-icon class="mr-2 mt-n1" color="error"
                    >mdi-file-document-edit</v-icon
                  >Select Applicant To Evaluate
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
                 <v-progress-linear
                  indeterminate
                  color="primary"
                  v-if="loadingDtApplicants"
                ></v-progress-linear>
            </template>

            <template v-slot:[`item.tender.tender_no`]="{ item }">
              <a
                @click.stop="tenderPreview(item.tender)"
                class="primary--text text--darken-1 text-decoration-underline"
                >{{ item.tender_no }}</a
              >
            </template>

            <template v-slot:[`item.tender.status`]="{ item }">
              <a v-if="item.tender.status.slug == 'open'" class="success--text">
                {{ item.tender.status.name }}
              </a>
              <a v-else class="grey--text">{{ item.tender.status.name }}</a>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="my-1">
                <v-btn
                  color="primary"
                  class="mx-1 text-capitalize"
                  v-if="evaluationStatus(item)"
                  outlined
                  @click="nextToStep2(item)"
                >
                  Evaluate <v-icon color="success">mdi-check-all</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-stepper-content>

        <v-stepper-content step="2">
          <TenderCard />
          <TenderMandatoryEvaluation ref="mandatory" />
          <TenderTechnicalEvaluation ref="technical" />
          <v-btn
            dark
            color="grey"
            @click="backToStep1"
            class="mb-1 mx-2"
            outlined
          >
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-btn dark color="primary" @click="nextToStep3" class="ml-5">
            Continue <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="3">
          <TenderFinancialEvaluation ref="financial" />
          <v-btn color="grey" @click="backToStep2" class="mb-1 mx-2" outlined>
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-btn color="primary" @click="nextToStep4" class="mb-1 mx-5" dark>
            Continue <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="4">
          <p class="mt-5">This action requires confirmation</p>
          <v-col md="3">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Corfirm with password"
              hint="At least 6 characters"
              counter
              @click:append="show1 = !show1"
              color="grey"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-btn
            color="grey white--text"
            @click="backToStep3"
            class="mb-1 mx-2"
            outlined
          >
            <v-icon>mdi-chevron-left</v-icon> Back
          </v-btn>

          <v-btn color="primary" dark class="mb-1 mx-2" @click="submitEvaluation">
            Save <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>
      </v-form>
    </v-stepper-items>
  </v-stepper>
</div>
</template>
<script>
import TenderCard from "@/components/TenderCard.vue";
import TenderTechnicalEvaluation from "@/components/TenderTechnicalEvaluation.vue";
import TenderFinancialEvaluation from "@/components/TenderFinancialEvaluation.vue";
import TenderMandatoryEvaluation from "@/components/TenderMandatoryEvaluation.vue";
import TenderService from "../../services/tender.service";
import moment from "moment";
import TenderBiddersService from "../../services/tender_bidders.service";

export default {
  components: {
    TenderTechnicalEvaluation,
    TenderCard,
    TenderFinancialEvaluation,
    TenderMandatoryEvaluation,
  },
  data() {
    return {
      progress_value: 20,
      loadingDtTender: true,
      loadingDtApplicants: true,
      show1: false,
      password: "",
      selected: true,
      dialog: false,
      valid: true,
      applicants: [],
      passwordRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      nameRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      emailRules: [
        (v) => !!v || "Valid E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "Min 6 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      items: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        company: {
          company_name: "",
        },
        email: "",
        phone: "",
      },
      defaultItem: {
        name: "",
        company: {
          company_name: "",
        },
        email: "",
        phone: "",
      },

      headers_applicants: [
        {
        text: "Tender No",
        value: "tender.tender_no",
        filterable: true,
        },
        {
          text: "User Name",
          value: "user.name",
        },
        {
          text: "Company Name",
          align: "start",
          filterable: true,
          value: "company.company_name",
        },
        {
          text: "Closing Date",
          value: "tender.closing_date",
        },
        {
          text: "Email",
          value: "user.email",
        },
        {
          text: "Phone",
          value: "user.phone",
        },
        {
          text: "Status",
          value: "tender.status",
        },
        { 
          text: "Actions", 
          value: "actions", 
          sortable: false 
        },
      ],
      headers: [
        {
          text: "Tender No",
          align: "start",
          filterable: true,
          value: "tender_no",
        },
        {
          text: "Tender Name",
          value: "title",
        },
        {
          text: "Closed Date",
          value: "closing_date",
        },
        {
          text: "Bidders Count",
          value: "bidders_count",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
          sortable: false,
        },
      ],
      e1: 1,
      docURI:
        "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
      tenderCategories: ["Open", "closed", "On Invitation"],

      breadcrumps: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Admin",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: "Tenders",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],

      suppliers: [
        {
          name: "Software Technologies Limited",
          location: "Westpoint Building",
          type: "Sole Proprietor",
          country: "Kenya",
          email: "support@stl-horizon.com",
          phone: "+254708665345",
        },
        {
          name: "Gulf African Bank",
          location: "EAC Building",
          type: "Limited",
          country: "Kenya",
          email: "gulfbank@sco.ke",
          phone: "+254754654345",
        },
        {
          name: "Kenya Electricity Generating Company (KenGen)",
          location: "Stima Building",
          type: "Private Limited Company",
          country: "Kenya",
          email: "kengen@groups.ke.co",
          phone: "+254708222211",
        },
        {
          name: "Mumias Sugar Company",
          location: "Mumia Central",
          type: "Limited Company",
          country: "Kenya",
          email: "mumias@sugaco.go.ke",
          phone: "+25470000455",
        },
      ],
    };
  },

  computed: {
    formTitle() {
      return this.$el === 1 ? "Received Bids" : "Evaluation";
    },
    formIcon() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formText() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    user() {
      return this.$store.state.auth.user;
    },
    selectedUser() {
      return this.$store.state.auth.selected_user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
  },

  created() {
    this.getApprovedApplications();
    this.getAllTenders();
  },

  methods: {
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    backToStep1() {
      this.e1 = 1;
      this.progress_value -= 20;
    },
    nextToStep2(item) {
      this.e1 = 2;
      this.progress_value += 20;
      this.editedItem.application_id = item.id;
      this.$store.dispatch("tender/setSelected", item.tender);
      this.$store.dispatch("auth/setSelectedUser", item.user);
      this.$refs.mandatory.getApplicantUploadedRequiredDocs();
      this.$refs.mandatory.getRequiredScore();
      this.$refs.technical.getApplicantUploadedTechnicalDocs();
      this.$refs.technical.getTechnicalScore();
    },
    backToStep2() {
      this.e1 = 2;
      this.progress_value -= 20;
    },
    nextToStep3() {
      this.e1 = 3;
      this.progress_value += 20;
      this.$refs.financial.getApplicantUploadedFinancialDocs();
      this.$refs.financial.getFinancialScore();
    },
    backToStep3() {
      this.e1 = 3;
      this.progress_value -= 20;
    },
    nextToStep4() {
      this.e1 = 4;
      this.progress_value += 20;
    },
    submitEvaluation() {
      this.$refs.form.validate();
      if (this.valid == true) {
        let conf = confirm(
          "Are you sure you want to submit evaluation? you cannot undo this action."
        );
        if (conf) {
          this.loading = true;
          let formData = {};
          formData.password = this.password;
          formData.tender_id = this.tender.id;
          formData.applicant_id = this.selectedUser.id;
          formData.evaluator_id = this.user.id;
          formData.application_id = this.editedItem.application_id;
          return TenderService.submitTenderEvaluation(formData).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Tender evaluation submitted!"
                );
                this.$router.replace("/dashboard");
              } else if (response.status == 201) {
                this.$store.dispatch("alert/error", "Invalid password!");
              } else if (response.status == 202) {
                this.$store.dispatch(
                  "alert/error",
                  "Missing all mandatory documents!"
                );
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Internal server error, please check the form and try again!"
                );
              }
            },
            (error) => {
              console.log(error);
              this.$store.dispatch("alert/error", error.response);
            }
          );
        }
      }
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    evaluationStatus(item) {
      if(item.tender_status == 3) {
        if(item.status == 0) {
          return true;
        }

        return false;
      }
    },

    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },
    getAllTenders() {
      return TenderService.getEvaluationTenders(this.user.id).then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.items = response.data.data;
            this.loadingDtTender = false;
          } else {
            this.items = [];
            this.loadingDtTender = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          this.loadingDtTender = false;
          console.log(error);
        }
      );
    },
    getAllBidders() {
      return TenderBiddersService.index().then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.applicants = response.data.data;
            this.loadingDtApplicants = false;
          } else {
            this.applicants = {};
            this.loadingDtApplicants = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.applicants = [];
          this.loadingDtApplicants = false;
          console.log(error);
        }
      );
    },
    getApprovedApplications() {
      return TenderBiddersService.approvable_index(this.user).then(
        (response) => {
          if (response.status == 200) {
            this.applicants = response.data.data;
            this.loadingDtApplicants = false;
          } else {
            this.applicants = {};
            this.loadingDtApplicants = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.applicants = {};
          this.loadingDtApplicants = false;
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getTenderApplicants() {
      let item = {};
      item.id = this.tender.id;
      return TenderService.getTenderApplicants(item).then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            this.applicants = response.data.data;
            this.loadingDtApplicants = false;
          } else {
            this.applicants = {};
            this.loadingDtApplicants = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.applicants = [];
          this.loadingDtApplicants = false;
          console.log(error);
        }
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.updateUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.storeUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>