<template>
  <div class="mx-auto my-3" max-width="100%">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <h4 class="grey--text ml-5">Evaluate Financial Documents.</h4>
    <v-divider></v-divider>
    <v-card-text>
      <v-row v-if="tender">
        <div v-for="(item, i) in tender.financial_documents" :key="i">
          <v-col
            cols="12"
            class="mt-n2"
            v-if="
              documentUploaded(
                applicant_uploaded_financial_docs,
                'document_id',
                item.id
              )
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                  <a
                    :href="
                      get_item_url(applicant_uploaded_financial_docs, item.id)
                    "
                    target="_blank"
                    class="d-block primary--text text--darken-1 text-decoration-none"
                  >
                    <v-icon x-large color="primary"
                      >mdi-arrow-down-bold-circle</v-icon
                    >{{ truncate(item.name, 37) }}
                  </a>
                </span>
              </template>
              <span
                >Click to download your uploaded :
                {{ truncate(item.name, 30) }}</span
              >
            </v-tooltip>
            <v-text-field
              v-model="score['score' + item.id]"
              @change="submitScore(score['score' + item.id], item.id)"
              color="grey"
              class="mx-1"
              outlined
              type="number"
              dense
            >
              <template v-slot:label>
                <span class="input__label" v-if="!score['score' + item.id]">
                  Score
                  <v-icon small color="error" class="mt-n2"
                    >mdi-star-outline</v-icon
                  ></span
                ><span class="input__label" v-else>
                  Score
                  <v-icon small color="primary" class="mt-n2"
                    >mdi-check</v-icon
                  ></span
                >
              </template>
            </v-text-field>
          </v-col>
        </div>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import TenderService from "../services/tender.service";

export default {
  name: "FinancialDocument",
  data: () => ({
    selection: 1,
    file: null,
    valid_doc: [
      {
        id: 1,
        title: "Yes",
      },
      {
        id: 2,
        title: "No",
      },
    ],
    score: {},
  }),
  created() {
    this.getFinancialScore();
    this.getApplicantUploadedFinancialDocs();
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    selected_user() {
      return this.$store.state.auth.selected_user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
    applicant_uploaded_financial_docs: {
      get: function () {
        return this.$store.state.tender.applicant_uploaded_financial_docs;
      },
      set: function (value) {
        this.$store.dispatch("tender/setApplicantUploadedFinancialDocs", value);
      },
    },
  },
  methods: {
    getFinancialScore() {
      return TenderService.getFinancialScore(
        this.tender.id,
        this.selected_user.id,
        this.user.id
      ).then(
        (response) => {
          if (response.status == 200) {
            this.score = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    submitScore(score, document_id) {
      console.log(document_id);
      console.log(score);
      let formData = {};
      formData.tender_id = this.tender.id;
      formData.applicant_id = this.selected_user.id;
      formData.evaluator_id = this.user.id;
      formData.document_id = document_id;
      formData.score = score;
      return TenderService.addFinancialScore(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Score updated");
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getApplicantUploadedFinancialDocs() {
      return TenderService.getApplicantUploadedFinancialDocs(
        this.tender.id,
        this.selected_user.id
      ).then(
        (response) => {
          if (response.status == 200) {
            this.applicant_uploaded_financial_docs = response.data.data;
            this.$store.dispatch(
              "tender/setApplicantUploadedFinancialDocs",
              response.data.data
            );
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    arraySearch(array, key, value) {
      let filteredArray = [];
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          filteredArray = array[i];
        }
      }
      return filteredArray;
    },
    documentUploaded(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          return true;
        }
      }
      return false;
    },
    get_item_url(array, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i]["document_id"] == value) {
          return array[i]["content"];
        }
      }
    },
    reserve() {},
    async getFileObject(input) {
      // let blob = await fetch(input.uploaded);
      let blob = await fetch(input.uploaded);
      return blob.size;
      // return blob;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file, item, tender) {
      console.log("uploading...");
      let formData = {};
      formData["tender_id"] = tender.id;
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = item.id;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      TenderService.addApplicantFinancialDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Tender Document uploaded!");
            this.file = null;
            this.getApplicantUploadedFinancialDocs();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            console.log(error.response.data.errors);
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
  },
};
</script>
